// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataListOption: null,
    respDataKey: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },

    GET_LIST_OPTION(state, payload) {
      state.respDataListOption = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.unshift(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    UPDATE(state, payload) {
      const index = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[index], payload)
    },
    UPDATE_LABEL(state, payload) {
      const index = state.respData.label.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.label[index], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/productCategory/get')
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/productCategory/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/productCategory/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/productCategory/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateLabel({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/productCategory/updateLabel', item)
          .then(response => {
            commit('UPDATE_LABEL', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
