<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <div
        class="item-container"
      >
        <b-card
          v-for="(categories ,indexCategory) in options.length +1"
          :key="indexCategory"
          no-body
          class="mb-0 item p-2"
        >
          <p>{{ $t('Category level') }} : {{ categories }} {{ selectedOption.hasOwnProperty(indexCategory-1) ? `(${$t('Choose from')} ${findName(selectedOption[indexCategory-1])})` : '' }}</p>
          <b-input-group :prepend="$t('Category name')">
            <b-form-input v-model="categoryName[indexCategory]" />
            <b-input-group-append>
              <b-button
                variant="success"
                @click="saveCategoryName(indexCategory)"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <hr>
          <b-input-group :prepend="$t('Search option')">
            <b-form-input v-model="searchOption[indexCategory]" />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="openModal({
                  data: {},
                  previousId: selectedOption.hasOwnProperty(indexCategory-1) ? selectedOption[indexCategory-1]: null,
                  position: categories-1,
                })"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <div class="scroller mt-1">
            <div
              class="scroller-content"
            >

              <b-table-simple
                hover
                small
                caption-top
                responsive
              >
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>
                      {{ $t('Image') }}
                    </b-th>
                    <b-th>
                      {{ $t('Name') }}
                    </b-th>
                    <b-th>
                      {{ $t('Action') }}
                    </b-th>
                  </b-tr>
                </b-thead>

                <b-tbody>
                  <template v-if="filterPosition(categories-1).length<1">
                    <b-tr>
                      <b-td
                        colspan="3"
                        class="text-center"
                      >
                        <p>
                          {{ $t('No matching records found') }}
                        </p>
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-if="filterPosition(categories-1).length>0">
                    <b-tr
                      v-for="(item,indexData) in filterPosition(categories-1)"
                      :key="indexData"
                      :variant="checkActive(categories-1, item._id)? 'primary':''"
                      @click.stop="onSelectOption(categories-1, item._id)"
                    >
                      <b-td>
                        <b-avatar
                          v-if="item.image"
                          size="40"
                          :src="item.image"
                        />
                        <b-avatar
                          v-else
                          size="40"
                          :src="require('@/assets/images/blank/no_image.png')"
                        />
                      </b-td>
                      <b-td>
                        <div
                          v-for="(name, indexName) in item.name"
                          :key="indexName"
                          class=""
                        >{{ name.lang.toUpperCase() }} : {{ name.value }}</div>
                      </b-td>
                      <b-td>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click.stop="openModal({
                              data: item,
                              previousId: selectedOption.hasOwnProperty(indexCategory-1) ? selectedOption[indexCategory-1]: null,
                              position: categories-1,
                            })"
                          >
                            <feather-icon
                              icon="EditIcon"
                            />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click.stop="deleteData(item._id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-td>
                    </b-tr>
                  </template>

                </b-tbody>

              </b-table-simple>

            </div>
          </div>

        </b-card>

      </div>

    </b-overlay>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BOverlay,
  BDropdownItem,
  BDropdown,
  BTableSimple,
  BTr,
  BTd,
  BTbody,
  BThead,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/productCategory'
import debounce from 'debounce'
import Modal from './Modal.vue'

const STORE_MODULE_NAME = 'productCategory'

export default {
  components: {
    BCard,
    BTr,
    BTd,
    BThead,
    BTh,
    BTbody,
    BTableSimple,
    BInputGroup,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BOverlay,
    BButton,
    BDropdown,
    BDropdownItem,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    Modal,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      show: false,
      modalData: {},
      isModalActive: false,
      categoryName: {},
      searchOption: {},
      selectedOption: {},
      perPageOptions,
    }
  },
  computed: {
    options() {
      const dataOption = Object.keys(this.selectedOption)
      return { data: dataOption, length: dataOption.length }
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.get()
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    saveCategoryName(position) {
      const obj = {
        name: this.categoryName[position] ? this.categoryName[position] : '',
        position,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/updateLabel`, obj)
        .then(() => {
          this.show = false
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    findName(id) {
      const index = this.respData.findIndex(e => e._id.toString() === id.toString())
      if (index > -1) {
        if (this.respData[index].name.length > 0) {
          const indexLang = this.respData[index].name.findIndex(e => e.lang === this.$i18n.locale)
          if (indexLang > -1) {
            return this.respData[index].name[indexLang].value
          }
          return this.respData[index].name[indexLang].value
        }
      }
      return ''
    },
    checkActive(position, id) {
      // console.log('checkActive ', this.selectedOption[position], id)
      if (this.selectedOption[position] === id) {
        return true
      }
      return false
    },
    // eslint-disable-next-line no-unused-vars
    filterPosition(position) {
      let data = this.respData
      // eslint-disable-next-line no-prototype-builtins
      if (position > 0) {
        data = data.filter(e => e.previousId !== null).filter(e => e.previousId.toString() === this.selectedOption[position - 1].toString())
      }
      data = data.filter(e => e.position.toString() === position.toString())

      // eslint-disable-next-line no-prototype-builtins
      if (this.searchOption.hasOwnProperty(position)) {
        const regex = new RegExp(this.searchOption[position], 'i')
        data = data.filter(p => (regex.test(p.name.map(n => n.value))))
      }
      return data
    },
    openModal(item) {
      this.isModalActive = true
      this.modalData = item
    },
    onSelectOption(position, id) {
      const obj = {
        [`${position}`]: id,
      }

      const keys = this.options.data

      keys.forEach(key => {
        // eslint-disable-next-line radix
        if (parseInt(key) > position) {
          delete this.selectedOption[key]
        }
      })

      this.selectedOption = {
        ...this.selectedOption,
        ...obj,
      }
    },
    get() {
      const obj = {

      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          const { label } = result.data

          for (let index = 0; index < label.length; index += 1) {
            console.log('fetch Success : ', result.data.label)
            const item = label[index]
            // eslint-disable-next-line no-shadow
            const obj = {
              [`${item.position}`]: item.name,
            }
            this.categoryName = {
              ...this.categoryName,
              ...obj,
            }
          }
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              .then(result => {
                this.show = false
                console.log('fetch Success : ', result)
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .v-select-custom {
    display: inline-block;
    width: 50;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .item-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    padding: 8px;
    height: 75vh;
    box-sizing: border-box;
    scrollbar-color: gray white;

    }
  .item {
    min-width: 450px;
    overflow: hidden;
  }
  .scroller {
    flex: 1; /* Take the remaining space */
    overflow-y: auto;
    scrollbar-color: gray white;
    width: 100%;
  }
  .scroller-content {
    display: flex;
    flex-direction: column;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  </style>
